export { default } from './TableSearcher'

// 工具方法
/**
 * 根据配置文件生成初始数据
 * @param {*} configs
 */
export function formatSearch (configs = []) {
  return configs.reduce((w, c) => {
    w[c.key] = c.defaultValue
    return w
  }, {})
}

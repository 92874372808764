<script>
import { defineComponent, ref, computed, watch, unref } from '@vue/composition-api'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const visible = ref(false)
    const model = ref({})
    const formRef = ref(null)

    watch(() => props.options, (options) => {
      const { items = [] } = options?.form
      model.value = items.reduce((w, c) => {
        w[c.key] = c.defaultValue
        return w
      }, {})
    }, { immediate: true })

    const hide = () => {
      visible.value = false
    }

    return {
      visible,
      show (mod) {
        if (mod) {
          const o = {}
          Object.keys(model.value).forEach(k => {
            o[k] = mod[k]
          })
          model.value = o
        }
        visible.value = true
      },
      hide,
      onClosed () {
        formRef.value && formRef.value.resetFields()
      },
      form: computed(() => {
        return props.options.form || { attrs: {}, items: [] }
      }),
      model,
      formRef,
      async handleSave () {
        if (formRef.value) {
          const valid = await formRef.value.validate()
          if (valid) {
            emit('confirm', { data: { ...unref(model) }, done: hide })
          }
        }
      }
    }
  }
})
</script>
<template>
  <el-dialog
    :title="title"
    :visible="visible"
    @close="hide"
    @closed="onClosed"
    :close-on-click-modal="false"
    v-bind="$attrs"
  >
    <div class="overflow-y-auto" style="max-height: 54vh">
      <div
        class="p-0.5 border border-blue-400 bg-blue-200 rounded mb-2"
        v-if="options.headerDesc"
      >
        <i class="el-icon-warning mx-1 text-blue-500 text-base"></i>
        <span class="text-gray-500">{{ options.headerDesc }}</span>
      </div>
      <el-form ref="formRef" v-bind="{ 'label-width': '80px', ...form.attrs }"
        size="small"
        label-position="left"
        :model="model"
      >
        <el-form-item
          :prop="item.key"
          v-for="item in form.items.filter(it => it.tag)"
          :key="item.key"
          :label="item.label"
        >
          <component :is="item.tag" v-model="model[item.key]" v-bind="{ style: 'width: 300px;', ...(item.attrs || {})}">
            <template v-if="item.tag === 'el-select'">
              <el-option
                v-for="it in item.slot.dataProvider()"
                :key="it[item.slot.key]"
                v-bind="item.slot.attrGetter(it)"
              ></el-option>
            </template>
          </component>
        </el-form-item>
      </el-form>
    </div>
    <template slot="footer">
      <el-button type="primary" size="small"
        @click="handleSave"
      >保存</el-button>
    </template>
  </el-dialog>
</template>

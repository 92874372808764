<script>
import { defineComponent, ref, unref } from '@vue/composition-api'
import TableSearcher, { formatSearch } from '@/components/table-searcher'
import GodTable from '@/components/god-table'
import SeedDialog from '@/components/seed-dialog'
import UploadDialog from '@/components/upload-dialog'
import XlsxDownloader from '@/components/xlsx-downloader'
import FastDialog from '@/components/fast-dialog'
import confirm from '@/components/common/Confirm'
// import uploadRefundOrderDialog from './upload-refund-order-dialog'
import {
  createTableSearcherParams,
  createRefundRecordOptions,
  createRefundDetailOptions,
  confirmPaymentOptions
} from './options'
import { API } from '@/service/api'
import { updateExpressNoDialog } from './updateExpressNoDialog'
import { Notification } from 'element-ui'

export default defineComponent({
  name: 'wayfair-refund',
  components: {
    FastDialog,
    UploadDialog,
    XlsxDownloader,
    // uploadRefundOrderDialog,
    TableSearcher,
    GodTable,
    SeedDialog
  },
  setup: function (_, { refs }) {
    const table = ref(null)
    const searcher = ref(null)
    const searcherParams = createTableSearcherParams()
    const refundFormDialogOptions = createRefundRecordOptions()
    const refundDetailDialogOptions = createRefundDetailOptions()
    const confirmRefundPaymentOptions = confirmPaymentOptions()
    const searchQuery = ref({
      ...formatSearch(searcherParams),
      step: '退货中'
    })

    const handleRefundFormConfrim = ({ data, done }) => {
      API.Sales.addWayfairRefundRecord(data)
        .then(() => {
          done()
        })
    }

    const handleEditRefundFinish = ({ data, done }) => {
      API.Sales.editWayfairRefundRecord(data)
        .then(() => {
          done()
        })
    }
    const handleConfirmPayment = ({ data, done }) => {
      API.Sales.setPaymentConfirm(data)
        .then((res) => {
          done()
          Notification.success({
            message: '设置成功',
            duration: 1500
          })
        })
    }
    const {
      fetchData,
      editableExpressData,
      updateExpressNo,
      clearExpressNo,
      updateTableFields
    } = updateExpressNoDialog()

    return {
      table,
      searcher,
      searchQuery,
      searcherParams,
      refundFormDialogOptions,
      refundDetailDialogOptions,
      confirmRefundPaymentOptions,
      handleSearch (params) {
        searchQuery.value = { ...params, step: unref(searchQuery).step }
        table.value.refresh()
      },
      dataProvider (pageData) {
        const model = searchQuery.value
        return API.Sales.getWaifairRefundList({
          ...model,
          ...pageData,
          updated_at_from: model.range ? model.range[0] : undefined,
          updated_at_to: model.range ? model.range[1] : undefined
        })
      },
      handleExport () {
        // const params = {
        //   date_start: this.search.start,
        //   date_end: this.search.end,
        //   warehouse_id: this.search.warehouseId ? this.search.warehouseId.join(',') : null
        // }
        refs.downloadDialog.show(searchQuery.value)
      },
      handleEditRecord (row) {
        const model = {
          id: row.id,
          price: row.price,
          reason: row.reason,
          created_at: row.created_at,
          remark: row.remark
        }
        refs.editDetailDialog && refs.editDetailDialog.show(model)
      },
      handleDeleteRefundRecord (id) {
        confirm(
          '提示',
          '系统将尝试调整所涉退货单的明细数量。'
        )
          .then(done => {
            API.Sales.deleteWayfairRefund(id)
              .then(() => {
                done()
              })
          })
      },
      handleEditRefundFinish,
      handleRefundFormConfrim,
      handleConfirmPayment,
      // UPDATE EXPRESS NO DIALOG
      openExpressDialog (d) {
        console.log(d)
        fetchData(d.po)
        refs.updateExpressDialog.show()
      },
      editableExpressData,
      updateExpressNo,
      clearExpressNo,
      updateTableFields,
      handleReturnStatus: function () {
        const items = this.$refs.table.getSelected()
        if (items === undefined || items.length === 0) {
          this.$alert('请至少选择一个退货明细')
          return false
        }
        confirm('提示', '确定将已选的退货明细设置为“已退货”？')
          .then((done) => {
            API.Sales.setReturned({ id: items }).then((res) => {
              done()
              Notification.success({
                message: '设置成功',
                duration: 1500
              })
            })
          })
      },
      handlePaymentConfirm () {
        const items = this.$refs.table.getSelected()
        if (items === undefined || items.length === 0) {
          this.$alert('请至少选择一个退货明细')
          return false
        }
        API.Sales.allReturn({ id: items }).then((res) => {
          refs.ConfirmPaymentDialog.show({ id: items })
        }).catch((err) => {
          console.log(err)
          // 弹出对话框提示存在退货中的明细
        })
      }
    }
  }
})
</script>
<template>
  <div>
    <el-row>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>wayfair</el-breadcrumb-item>
        <el-breadcrumb-item>销售退货单</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-tabs v-model="searchQuery.step" @tab-click="$refs.table.refresh()">
      <el-tab-pane
        v-for="it in $store.getters.sysConfig.WayfairRefundDetail.step"
        :key="it"
        :label="it"
        :name="it"
        ></el-tab-pane>
    </el-tabs>
    <div class="flex items-start justify-between">
      <div class="flex">
        <table-searcher
          class="inline-block"
          ref="searcher"
          :params="searcherParams"
          @search="handleSearch"
        />
        <div class="mt-1">
          <el-button size="small" type="primary" @click="handleExport"
            >下载</el-button
          >
        </div>
      </div>
      <!-- 靠右 -->
      <el-dropdown class="mt-1">
        <el-button type="primary" size="mini"
          >上传退货单明细 <i class="el-icon-arrow-down el-icon--right"></i
        ></el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            @click.native="$refs['upload-refund-dialog'].show(1)"
            >上传退货明细</el-dropdown-item
          >
          <el-dropdown-item @click.native="$refs['seedDialog'].show()"
            >新增退货明细</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <god-table
      ref="table"
      prefetch
      paging
      :data-provider="dataProvider"
      height="calc(100vh - 334px)"
    >
      <el-table-column type="selection" width="40px"> </el-table-column>
      <el-table-column prop="po" label="PO" width="120px"></el-table-column>
      <el-table-column prop="sku" label="SKU"></el-table-column>
      <el-table-column prop="no" label="仓库SKU"></el-table-column>
      <el-table-column prop="price" label="Price" width="60px"></el-table-column>
      <el-table-column prop="qty" label="Qty" width="50px">
        <template slot-scope="scope">
          <div>
            <span>{{ scope.row.qty }}</span>
            <el-tag type="info" v-if="scope.row.act">{{ scope.row.act }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="_status" label="Status"></el-table-column>
      <el-table-column prop="warehouse" label="退货仓库"></el-table-column>
      <el-table-column prop="io_no" label="退货单号"></el-table-column>
      <el-table-column prop="express_no" label="快递单号"
      >
        <template slot-scope="{ row }">
          <el-button type="text"
            class="select-text"
            @click="openExpressDialog(row)"
          >{{ row.express_no || '未设置，点击此处进行设置'}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="updated_at" label="更新日期" width="100px"></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column prop="sett_status" label="账单确认" width="80px">
        <template slot-scope="scope">
          <span v-if="scope.row.sett_status===0">未确认</span>
          <span v-else>已确认</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="85px">
        <template slot-scope="{ row }">
          <!-- 编辑已完成 -->
          <el-button type="text" size="small"
            @click="handleEditRecord(row)"
          >编辑</el-button>
          <el-button class="text-red-500 hover:text-red-500 focus:text-red-500" type="text" size="small"
            v-if="searchQuery.step === '退货中'"
            @click="handleDeleteRefundRecord(row.id)"
          >删除</el-button>
        </template>
      </el-table-column>
    </god-table>
    <div>
      <el-button
        v-if="searchQuery.step === '退货中'"
        type="primary"
        size="small"
        @click="handleReturnStatus"
      >
        置为已退货
      </el-button>
      <el-button
        v-if="searchQuery.step === '已退货'"
        type="primary"
        size="small"
        @click="handlePaymentConfirm"
        >账单确认</el-button
      >
<!--      <button type="button" @click="openExpressDialog(null)">更新运单号</button>-->
    </div>

    <!-- <upload-refund-order-dialog
      ref="upload-refund-order-dialog"
    ></upload-refund-order-dialog> -->
    <SeedDialog
      ref="seedDialog"
      width="500px"
      title="新增退货记录"
      :options="refundFormDialogOptions"
      @confirm="handleRefundFormConfrim"
    />
    <!-- 编辑列表 -->
    <SeedDialog
      ref="editDetailDialog"
      width="500px"
      title="编辑明细"
      :options="refundDetailDialogOptions"
      @confirm="handleEditRefundFinish"
    />
    <!--确认账单-->
    <SeedDialog
      ref="ConfirmPaymentDialog"
      width="500px"
      title="确认退货账单"
      :options="confirmRefundPaymentOptions"
      @confirm="handleConfirmPayment"
    ></SeedDialog>
    <UploadDialog
      ref="upload-refund-dialog"
      type="SALES_WAYFAIR_REFUND"
    />
    <XlsxDownloader ref="downloadDialog" type="SALES_WAYFAIR_REFUND" />
    <FastDialog
      title="更新运单号"
      @closed="clearExpressNo"
      ref="updateExpressDialog"
      width="800px"
    >
      <div>
        <div
          class="p-0.5 border border-blue-400 bg-blue-200 rounded mb-2"
        >
          <i class="el-icon-warning mx-1 text-blue-500 text-base"></i>
          <span class="text-gray-500">此操作很可能会影响同PO号的其他退货单，系统将自动调整海外仓退货单。</span>
        </div>
        <el-table :data="editableExpressData" border>
          <el-table-column prop="sku" label="SKU">
            <template slot-scope="scope">
              <span>{{ scope.row.sku }}</span>
            </template>
          </el-table-column>
          <el-table-column label="仓库SKU">
            <template slot-scope="scope">
              <el-input
                :value="scope.row.no"
                @input="$event => updateTableFields(scope.$index, 'no', $event)"
                size="small"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="qty" label="Qty">
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.qty"
                :value="scope.row.qty"
                @change="$event => updateTableFields(scope.$index, 'qty', $event)"
                :min="0"
                :precision="0"
                size="small"
                :controls="false"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column prop="warehouse" label="退货仓库">
            <template slot-scope="scope">
              <el-select v-model="scope.row.warehouse_id" size="small"
                :value="scope.row.warehouse_id"
                @change="$event => updateTableFields(scope.$index, 'warehouse_id', $event)"
              >
                <el-option
                  v-for="it in $store.getters.sysConfig.warehouselist"
                  :key="it.id"
                  :label="it.name"
                  :value="it.id"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="tracking_no" label="TrackingNO">
            <template slot-scope="scope">
              <el-input
                size="small"
                :value="scope.row.express_no"
                @input="$event => updateTableFields(scope.$index, 'express_no', $event)"
              ></el-input>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template slot="footer">
        <el-button type="primary" size="small"
          @click="updateExpressNo($refs['updateExpressDialog'].hide)"
        >保存</el-button>
      </template>
    </FastDialog>
  </div>
</template>

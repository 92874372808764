import { computed, reactive, unref } from '@vue/composition-api'
import { httpClient } from '@/core'
import { CONFIG_MAP } from './config'

export function useUpload (props) {
  const config = computed(() => {
    return CONFIG_MAP[props.type] || {
      title: '导入',
      buttonText: '上传'
    }
  })
  return reactive({
    config: unref(config),
    ui: {
      title: config.value.title,
      // 头部显示的文字
      headerExplain: config.value.headerExplain,
      description: config.value.description,
      buttonText: config.value.buttonText,
      // 进度条
      loadedSize: 0,
      totalSize: 0
    }
  })
}

const defaultOptions = {
  // 上传地址
  url: '',
  // 上传时的文件key
  name: 'files',
  multiple: false,
  useDir: false,
  // 上传成功结束
  onUploaded: null,
  // 终止
  onCancel: null,
  // 上传结束（正常结束或终止都会触发）
  onEnd: null,
  // 上传进度
  onProgress: null,
  // 选中文件的回调
  onPickFile: null
}

/**
 * 判断参数是否为一个函数
 * @param {unknow} mayBeIsAFunc
 */
const isExecutable = mayBeIsAFunc => typeof mayBeIsAFunc === 'function'

/**
 * TODO: 尝试用sfc重构upload
 */
export default class Uploader {
  // 上传时携带的桉树
  _params = {}
  _input = null
  _options = {}

  _httpHandle = null

  _files = []

  constructor (options = {}) {
    this._options = Object.assign({}, defaultOptions, options)
    this._init()
  }

  _init () {
    const { multiple, useDir } = this._options
    this._input = document.createElement('input')
    this._input.setAttribute('type', 'file')
    if (useDir) {
      this._input.setAttribute('webkitdirectory', true)
    } else if (multiple) {
      this._input.setAttribute('multiple', true)
    }
    this._input.addEventListener('change', this._onInputChange)
  }

  _onInputChange = (e) => {
    const { files = [] } = e.target
    if (files.length === 0) {
      throw new Error(`未找到上传文件${e}`)
    }
    this._files = files
    isExecutable(this._options.onPickFile) &&
      this._options.onPickFile(files)
    // const next = () => this._startUpload(files)

    // isExecutable(this._options.beforeUpload)
    //   ? this._options.beforeUpload(files, next)
    //   : this._startUpload(files)
  }

  _startUpload (files = []) {
    const { url, name } = this._options
    const params = this._params
    const formData = new FormData()
    files.forEach(file => {
      formData.append(name, file)
    })
    // format params
    Object.keys(params).forEach(key => {
      formData.append(key, params[key])
    })

    this._httpHandle = httpClient.cPost()

    this._httpHandle.run(url, formData, {
      timeout: 0,
      onUploadProgress: e => {
        isExecutable(this._options.onProgress) &&
        this._options.onProgress(e)
      }
    })
      .then(res => {
        isExecutable(this._options.onUploaded) &&
        this._options.onUploaded(res, files)
      })
      .finally(() => {
        this._endUpload()
        // 结束的回调
        isExecutable(this._options.onEnd) &&
        this._options.onEnd()
      })
  }

  _endUpload = () => {
    this._input.value = null
    this._params = {}
    this._files = []
  }

  pickFile () {
    this._input.click()
    // 获取文件用于后续上传
  }

  clearFile (i) {
    this._input.value = null
    if (i !== undefined) {
      this._files.splice(i, 1)
    } else {
      this._files = []
    }
  }

  startUpload (params = {}) {
    this._params = params
    this._startUpload(this._files)
  }

  stop () {
    // 结束的回调
    if (this._httpHandle && this._httpHandle.cancel) {
      this._httpHandle.cancel()

      isExecutable(this._options.onCancel) &&
      this._options.onCancel()
      // 结束的回调
      isExecutable(this._options.onEnd) &&
      this._options.onEnd()
    }
  }

  dispose () {
    if (this._input) {
      this._input.removeEventListener('change', this._onInputChange)
    }
    this._input = null
  }
}

Uploader.getUploader = options => new Uploader(options)

<script>
import { defineComponent, ref } from '@vue/composition-api'
import Uploader, { useUpload } from './upload'
import { Message } from 'element-ui'

export default defineComponent({
  props: {
    type: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const visible = ref(false)
    const selectedFile = ref(null)
    const { ui, config } = useUpload(props)

    const uploader = Uploader.getUploader({
      // 上传地址
      url: config.endpoint,
      // 上传时的文件key
      name: config.name,
      onPickFile (files) {
        files[0] && (selectedFile.value = files[0])
      },
      onProgress: e => {
        ui.loadedSize = e.loaded
        ui.totalSize = e.total
      },
      onEnd () {
        selectedFile.value = null
        ui.loadedSize = 0
        ui.totalSize = 0
      },
      onUploaded (res) {
        Message.success(res.msg)
        // 成功后需要关闭弹窗
        visible.value = false
        hide()
      }
    })

    const show = () => {
      visible.value = true
    }
    const hide = () => {
      visible.value = false
      uploader.stop()
    }

    return {
      ui,
      visible,
      show,
      hide,
      handlePickFile () {
        uploader.pickFile()
      },
      handleUpload () {
        uploader.startUpload()
      },
      handleClearFile (i) {
        uploader.stop()
        uploader.clearFile(i)
        selectedFile.value = null
      },
      formatProgeressText () {
        const { loadedSize, totalSize } = ui
        if (totalSize === 0) return 0
        return Math.round(loadedSize / (totalSize / 100))
      },
      selectedFile
    }
  }
})
</script>
<template>
  <el-dialog
    width="560px"
    :title="ui.title"
    :visible="visible"
    @close="hide"
    :close-on-click-modal="false"
  >
    <div>
      <div
        class="p-0.5 border border-blue-400 bg-blue-200 rounded mb-4"
        v-if="ui.headerExplain"
      >
        <i class="el-icon-warning mx-1 text-blue-500 text-base"></i>
        <span class="text-gray-500" v-html="ui.headerExplain"></span>
      </div>
      <el-button size="small" type="primary"
        @click="handlePickFile"
      >选取excel文件</el-button>
      <div
        class="mt-2"
        v-if="selectedFile"
      >
        <div class="flex justify-between">
          <span class="text-xs">{{ selectedFile.name }}</span>
          <i class="el-icon-close cursor-pointer"
            @click="handleClearFile()"
          ></i>
        </div>
        <el-progress
          v-if="ui.totalSize > 0"
          :percentage="formatProgeressText()"></el-progress>
      </div>
      <div class="mt-2" v-html="ui.description"></div>
    </div>
    <template slot="footer">
      <el-button size="small" type="primary"
        :disabled="!selectedFile"
        @click="handleUpload"
      >{{ ui.buttonText }}</el-button>
    </template>
  </el-dialog>
</template>

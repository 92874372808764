<script>
import { defineComponent, reactive, ref, onMounted } from '@vue/composition-api'

function pageDataFilter (res) {
  const { data = [], total = 0 } = res
  return { data, total }
}

export default defineComponent({
  name: 'god-table',
  props: {
    // 是否展示分页
    paging: {
      type: Boolean,
      default: false
    },
    // 组件加载完毕后立即执行数据拉取
    prefetch: {
      type: Boolean,
      default: false
    },
    // 调用外部方法获取数据 返回promise
    dataProvider: {
      required: true,
      type: Function
    }
  },
  setup (props) {
    const pageData = reactive({
      page: 1,
      perPage: 100,
      total: 0
    })

    const loading = ref(false)
    const sourceData = ref([])
    let items
    const fetchData = async () => {
      if (!props.dataProvider) {
        throw new Error('god-table 需要 `dataProvider` 获取数据源')
      }
      loading.value = true
      try {
        // 将pageData转换为服务端对应的page字段
        const p = { page: pageData.page, per_page: pageData.perPage }
        const res = await props.dataProvider(p)
        const { data = [], total } = pageDataFilter(res.dat)
        sourceData.value = data
        pageData.total = total
      } catch (e) {
        console.error(e)
      } finally {
        loading.value = false
      }
    }

    const refresh = () => {
      pageData.page = 1
      fetchData()
    }
    const reload = () => {}

    onMounted(() => {
      props.prefetch && refresh()
    })

    return {
      loading,
      sourceData,
      pageData,
      handlePageChange (index) {
        pageData.page = index
        // TODO: 触发数据拉取
        fetchData()
      },
      handleSizeChange (size) {
        pageData.page = 1
        pageData.pageSize = size
        // TODO: 触发数据拉取
        fetchData()
      },
      refresh,
      reload,
      handleSelect (selection) {
        items = selection.map(item => {
          return item.id
        })
      },
      handleSelectAll (selection) {
        items = selection.map(item => {
          return item.id
        })
      },
      getSelected () {
        return items
      }
    }
  }
})
</script>
<template>
  <div v-loading="loading">
    <el-table :data="sourceData" border stripe width="100%" v-bind="$attrs" v-on="$listeners" @select="handleSelect" @select-all="handleSelectAll">
      <slot></slot>
    </el-table>
    <el-pagination
        v-if="paging"
        :current-page.sync="pageData.page"
        :page-size="pageData.perPage"
        :page-sizes="[100, 200, 300]"
        layout="total, prev, pager, next, sizes, jumper"
        :total="pageData.total"
        @current-change="handlePageChange"
        @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
import { WIDGET_TYPE } from './option'
import useModel from './useModel'
import { defineComponent, computed } from '@vue/composition-api'

const TAG_MAP = {
  [WIDGET_TYPE.INPUT]: 'el-input',
  [WIDGET_TYPE.SELECT]: 'el-select',
  [WIDGET_TYPE.DATE_PICKER]: 'el-date-picker'
}

export default defineComponent({
  props: {
    options: {
      type: Object,
      required: true
    },
    value: {
      required: true
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  setup (props, { emit }) {
    const { attrs, listeners } = useModel(props, emit)

    return {
      listeners,
      attrs,
      tagName: TAG_MAP[props.options.widget],
      optionList: computed(() => {
        if (props.options.options && props.options.options.dataProvider) {
          return props.options.options.dataProvider()
        } else {
          return []
        }
      }),
      optionConfig: props.options.options
    }
  }
})
</script>

<template>
  <component :is="tagName" v-bind="attrs" v-on="listeners">
    <template v-if="tagName === 'el-select'">
      <el-option
        v-for="item in optionList"
        :key="item[optionConfig.key]"
        v-bind="optionConfig.attrGetter(item)"
        >
      </el-option>
    </template>
  </component>
</template>

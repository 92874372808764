import { computed } from '@vue/composition-api'
import { WIDGET_TYPE } from './option'

const MODEL_TRIGGER = {
  [WIDGET_TYPE.INPUT]: {
    prop: 'value',
    event: 'input'
  },
  [WIDGET_TYPE.SELECT]: {
    prop: 'value',
    event: 'change'
  },
  [WIDGET_TYPE.DATE_PICKER]: {
    prop: 'value',
    // 日期选择器的触发事件是input, 不是change， 文档有误
    event: 'input'
  }
}

export default function (props, emit) {
  const trigger = MODEL_TRIGGER[props.options.widget]

  const attrs = computed(() => {
    const { attrs = {} } = props.options
    return trigger ? { [trigger.prop]: props.value, ...attrs } : attrs
  })
  const listeners = computed(() => {
    if (props.listeners && props.listeners[trigger.event]) {
      console.warn('listeners 中的 model 事件已被占用，将被弃用')
    }
    const events = trigger
      ? {
        [trigger.event]: v => {
          emit('change', v)
        }
      }
      : {}
    return Object.assign(props.listeners || {}, events)
  })

  return {
    attrs,
    listeners
  }
}

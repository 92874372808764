import { ref } from '@vue/composition-api'
import { API } from '@/service/api'

export function updateExpressNoDialog () {
  const editableExpressData = ref([])

  let c = null

  const fetchData = po => {
    const { run, cancel } = API.Sales.getWayfairExpressNoByPo()
    c = cancel
    run(po)
      .then(res => {
        editableExpressData.value = res.dat
      })
      .finally(() => {
        c = null
      })
  }

  return {
    fetchData,
    // 可修改的运单数据
    editableExpressData,
    // 保存
    updateExpressNo (done) {
      API.Sales.editWayfairExpressNo({ express_data: editableExpressData.value })
        .then(() => {
          done()
        })
    },
    updateTableFields (index, key, val) {
      editableExpressData.value[index][key] = val
    },
    // 清空selectedExpress数据
    clearExpressNo () {
      c && c()
      c = null
      editableExpressData.value = []
    }
  }
}

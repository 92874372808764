import { cSelect, cDatePicker, cInput } from '@/components/form-item-gen'
import store from '@/store'

export function createTableSearcherParams () {
  return [
    // SKU
    cInput('sku', '', {
      attrs: {
        type: 'textarea',
        rows: 1,
        placeholder: '商品SKU',
        clearable: true
      }
    }),
    cInput('no', '', {
      attrs: {
        type: 'textarea',
        rows: 1,
        placeholder: '仓库SKU',
        clearable: true
      }
    }),
    // 订单号
    cInput('po', '', {
      attrs: {
        type: 'textarea',
        rows: 1,
        placeholder: '订单号',
        clearable: true
      }
    }),
    // 状态
    cSelect('status', null, {
      attrs: {
        placeholder: '状态',
        clearable: true,
        style: {
          width: '130px'
        }
      },
      options: {
        key: 'key',
        dataProvider: () => store.getters.sysConfig.WayfairRefundDetail.status,
        attrGetter: it => {
          return {
            label: it,
            value: it
          }
        }
      }
    }),
    // 快递单号
    cInput('express_no', '', {
      attrs: {
        type: 'textarea',
        rows: 1,
        placeholder: '快递单号'
      }
    }),
    // 最后更新
    cDatePicker('range', null, {
      attrs: {
        style: {
          width: '380px'
        },
        clearable: true,
        type: 'daterange',
        'range-separator': '至',
        'start-placeholder': '开始日期',
        'end-placeholder': '结束日期',
        'value-format': 'yyyy-MM-dd'
      }
    }),
    cSelect('sett_status', null, {
      attrs: {
        placeholder: '账单确认',
        clearable: true,
        style: {
          width: '100px'
        }
      },
      options: {
        key: 'key',
        dataProvider: () => [{ id: 0, name: '未确认' }, { id: 1, name: '已确认' }],
        attrGetter: it => {
          return {
            label: it.name,
            value: it.id
          }
        }
      }
    })
  ]
}

export function createRefundRecordOptions () {
  return {
    headerDesc: '“PO-SKU-运单号”不允许与系统中已有数据重复。',
    form: {
      attrs: {
        'label-width': '134px',
        rules: {
          po: [{ required: true, message: '请输入po', trigger: 'blur' }],
          sku: [{ required: true, message: '请输入sku', trigger: 'blur' }],
          qty: [{ required: true, message: '请输入qty', trigger: 'blur' }],
          warehouse_id: [
            { required: true, message: '请选择仓库', trigger: 'change' }
          ],
          ra_notify_date: [
            {
              required: true,
              message: '请选择首次退货通知日期',
              trigger: 'change'
            }
          ]
        }
      },
      items: [
        { key: 'po', label: 'PO', tag: 'el-input', defaultValue: '' },
        { key: 'sku', label: 'SKU', tag: 'el-input', defaultValue: '' },
        { key: 'no', label: '仓库SKU', tag: 'el-input', defaultValue: '' },
        {
          key: 'qty',
          label: 'QTY',
          tag: 'el-input-number',
          defaultValue: undefined,
          attrs: { controls: false, placeholder: '数量', min: 1, precision: 0 }
        },
        {
          key: 'price',
          label: 'Price',
          tag: 'el-input-number',
          defaultValue: undefined,
          attrs: { controls: false, placeholder: '价格', min: 0, precision: 2 }
        },
        {
          key: 'warehouse_id',
          label: '仓库',
          tag: 'el-select',
          defaultValue: null,
          slot: {
            key: 'id',
            dataProvider: () => store.getters.sysConfig.warehouselist,
            attrGetter: it => {
              return {
                label: it.name,
                value: it.id
              }
            }
          }
        },
        {
          key: 'express_no',
          label: '运单号',
          tag: 'el-input',
          defaultValue: ''
        },
        {
          key: 'created_at',
          label: '退货时间',
          tag: 'el-date-picker',
          defaultValue: null,
          attrs: { 'value-format': 'yyyy-MM-dd' }
        },
        {
          key: 'ra_notify_date',
          label: '首次退货通知日期',
          tag: 'el-date-picker',
          defaultValue: null,
          attrs: { 'value-format': 'yyyy-MM-dd' }
        },
        {
          key: 'rs_notify_date',
          label: '运单通知时间',
          tag: 'el-date-picker',
          defaultValue: null,
          attrs: { 'value-format': 'yyyy-MM-dd' }
        },
        { key: 'remark', label: '备注', tag: 'el-input', defaultValue: '' }
      ]
    }
  }
}

// 编辑明细
export function createRefundDetailOptions () {
  return {
    form: {
      items: [
        { key: 'id', defaultValue: null },
        {
          key: 'price',
          label: '价格',
          tag: 'el-input-number',
          defaultValue: undefined,
          attrs: { controls: false, placeholder: '价格', min: 0, precision: 2 }
        },
        { key: 'reason', label: '退货原因', tag: 'el-input', defaultValue: '', attrs: { type: 'textarea' } },
        {
          key: 'created_at',
          label: '退货日期',
          tag: 'el-date-picker',
          defaultValue: null,
          attrs: {
            'value-format': 'yyyy-MM-dd'
          }
        },
        { key: 'remark', label: '备注', tag: 'el-input', defaultValue: '', attrs: { type: 'textarea' } }
      ]
    }
  }
}

export function confirmPaymentOptions () {
  return {
    form: {
      items: [
        { key: 'id', defaultValue: null },
        { key: 'voucher_no', label: '账单号', tag: 'el-input' },
        { key: 'note', label: '备注', tag: 'el-input', attrs: { type: 'textarea' } }
      ]
    }
  }
}

<script>
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  setup () {
    const visible = ref(false)

    return {
      visible,
      show () {
        visible.value = true
      },
      hide () {
        visible.value = false
      }
    }
  }
})
</script>

<template>
  <el-dialog
    @close="hide"
    :visible="visible"
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-modal="false"
  >
    <slot></slot>
    <template slot="footer">
      <slot name="footer"></slot>
    </template>
  </el-dialog>
</template>

export { default } from './GodTable'

export function modifiField (origin = {}, fieldName, callback) {
  if (typeof callback !== 'function') {
    throw new TypeError('callback must be a function')
  }
  if (!origin[fieldName]) {
    return origin
  }
  const d = origin[fieldName]
  const fixedData = callback(d)
  if (typeof fixedData !== 'object') {
    throw new TypeError('callback should return an object')
  }
  const r = { ...fixedData, ...origin }
  delete r[fieldName]
  return r
}

// import { baseUrl } from '@/config/env'

const TYPE = {
  SALES_WAYFAIR_REFUND: 'SALES_WAYFAIR_REFUND',
  // 款项确认
  SALES_WAYFAIR_PAYMENT_CONFIRMATION: 'SALES_WAYFAIR_PAYMENT_CONFIRMATION'
}

export const CONFIG_MAP = {
  [TYPE.SALES_WAYFAIR_REFUND]: {
    // 弹窗标题
    title: '上传导入退货单明细',
    // 表单文件字段名称
    name: 'excel',
    endpoint: '/sales/wayfair/createRefundByExcel', // '/wayfair/refund/import',
    // 头部显示的文字
    headerExplain: '',
    description: `
    <p>
    相同PO、SKU、运单号只能导入一次， <a class="text-blue-400" href="https://sd.resource.soffeedesign.net/tpl/Wayfair-退货单导入格式.xlsx?t=${Date.now()}">退货单模板文件下载</a>
    </p>`,
    buttonText: '上传'
  },
  [TYPE.SALES_WAYFAIR_PAYMENT_CONFIRMATION]: {
    title: '款项确认',
    name: 'excel',
    endpoint: 'https://jsonplaceholder.typicode.com/posts/',
    headerExplain: '款项确认后，相应明细将被执行自动录单（商贸系统或SD销售单）；同一个PO和SKU明细只会被执行录入一次。',
    description: `
    <p>
    模板文件下载 <a class="text-blue-400" href="https://sd.resource.soffeedesign.net/tpl/Wayfair-订单财务确认.csv?t=${Date.now()}">Wayfair订单款项确认模板.xls</a>
    </p>`,
    buttonText: 'Upload'
  }
}

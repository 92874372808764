<script>
import FormItemGen from '@/components/form-item-gen'
import { reactive } from '@vue/composition-api'

export default {
  name: 'table-searcher',
  components: {
    FormItemGen
  },
  props: {
    params: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    let model = {}
    const mountModelItem = (k, v) => {
      model[k] = v
    }

    const searchList = props.params.map(p => {
      const { key, defaultValue, ...other } = p
      mountModelItem(key, defaultValue)
      return {
        key,
        ...other
      }
    })
    model = reactive(model)

    return {
      model,
      searchList,
      handleChange (e, key) {
        model[key] = e
      },
      handleSearch () {
        emit('search', model)
      }
    }
  }
}
</script>
<template>
  <el-form inline size="small">
    <el-form-item
      v-for="item in searchList"
      :key="item.key"
      :label="item.label"
    >
      <FormItemGen :options="item" :value="model[item.key]" @change="e => handleChange(e, item.key)" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" size="small" @click="handleSearch">搜索</el-button>
    </el-form-item>
    <el-form-item>
      <slot name="action"></slot>
    </el-form-item>
  </el-form>
</template>

import { WIDGET_TYPE } from './option'

function cFormItem (widget, key, label) {
  return {
    widget,
    key,
    label
  }
}

export function cInput (key, defaultValue = null, {
  label,
  attrs = {},
  listeners = null
}) {
  attrs = { size: 'small', ...attrs }
  return {
    defaultValue,
    attrs,
    listeners,
    ...cFormItem(WIDGET_TYPE.INPUT, key, label)
  }
}

export function cSelectOptions (key) {
  const attrGetter = _ => {
    return { label: '', value: '' }
  }
  return {
    key,
    dataProvider: () => [],
    attrGetter
  }
}

export function cSelect (key, defaultValue = null, {
  label,
  attrs = {},
  listeners = null,
  options = cSelectOptions('id')
}) {
  attrs = { size: 'small', ...attrs }
  return {
    defaultValue,
    attrs,
    listeners,
    options,
    ...cFormItem(WIDGET_TYPE.SELECT, key, label)
  }
}

export function cSelectMultiple (key, defaultValue = [], {
  label,
  attrs = {},
  listeners = null,
  options
}) {
  attrs = { ...attrs, multiple: true }
  return cSelect(key, defaultValue, {
    label,
    attrs,
    listeners,
    options
  })
}

export function cDatePicker (key, defaultValue = [], {
  label,
  attrs = {},
  listeners = null
}) {
  attrs = { size: 'small', ...attrs }
  return {
    defaultValue,
    attrs,
    listeners,
    ...cFormItem(WIDGET_TYPE.DATE_PICKER, key, label)
  }
}

export function cDateRangePicker (key, defaultValue = [], config) {
  config.attrs = { ...config.attrs, type: 'daterange' }
  return cDatePicker(key, defaultValue, config)
}
